import React, { useState } from "react";
import { Card, Modal, Image, Select } from "antd";
import { months } from "../../Configs/Config";
import "./Gallery.css"

const { Meta } = Card;

const GalleryPage = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const showImages = (month) => {
    setCurrentMonth(month);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentMonth(null);
  };

  const handleYearChange = (year) => {
    setSelectedYear(year);
    // Optional: Filter or update gallery based on the selected year
    console.log("Selected Year:", year);
  };

  // Generate years from 2000 to current year
  const years = [];
  for (let year = 2000; year <= new Date().getFullYear(); year++) {
    years.push(year);
  }

  return (
    <div className="max-w-screen-lg mx-auto p-3">
      {/* Year Selector */}
      <div style={{ marginBottom: "20px", textAlign: "right" }}>
        <Select
          value={selectedYear}
          onChange={handleYearChange}
        >
          {years.map((year) => (
            <Select.Option key={year} value={year}>
              {year}
            </Select.Option>
          ))}
        </Select>
      </div>

      {/* Gallery Cards */}
      <div
        className="grid grid-cols-3 gap-2"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: "8px",
        }}
      >
        {months.map((month, index) => (
          <Card
            key={index}
            hoverable
            cover={
              <img
                alt={month.name}
                src="https://wach-gallery.s3.ap-south-1.amazonaws.com/slide-2.jpg"
                className="h-30 object-cover block mx-auto"
              />
            }
            onClick={() => showImages(month)}
            style={{ width: "100%", backgroundColor: month.color ,  border: `10px solid ${month.color}`,}}
          >
            <Meta
              title={<span style={{ color: "white" }}>{month.name}</span>} 
            />
          </Card>
        ))}
      </div>

      {/* Modal to show images */}
      <Modal
        title={currentMonth?.name || ""}
        open={isModalVisible}
        footer={null}
        onCancel={handleCancel}
        width="80%"
      >
        <div
          className="grid grid-cols-3 gap-4"
          style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "16px" }}
        >
          {currentMonth?.images.map((image, index) => (
            <Image key={index} src={image} alt={`Image ${index + 1}`} />
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default GalleryPage;
