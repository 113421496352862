import React, { useEffect, useState } from 'react';
import { Table, Input, Button, Select, Row, Col } from 'antd';
import { ProfileOutlined, ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import { DonationTableConfig } from "../Configs/Config";
import useWachDonationDetails from './Hooks/getDonarDetails';
import { ResetTvOutlined } from '@mui/icons-material';

const DonarDetails = () => {
    const [donarDetails, setDonarDetails] = useState();
    const [filteredData, setFilteredData] = useState(donarDetails?.donationDetailsList);
    const [searchedColumn, setSearchedColumn] = useState('');
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    // Get the current month and year
    const currentMonth = new Date().getMonth() + 1;  // 1-based index for months
    const currentYear = new Date().getFullYear();  // Current year

    // Set default values for month and year
    useEffect(() => {
        setSelectedMonth(currentMonth); // store current month as number (1 for January, etc.)
        setSelectedYear(currentYear);
    }, [currentMonth, currentYear]);

    //to get donar details
    const { mutate: mutateGetWachDonationDetails, isLoading, data: successDonarDetails } = useWachDonationDetails();

    useEffect(() => {
        mutateGetWachDonationDetails({ month: selectedMonth, year: selectedYear });
    }, [selectedMonth,selectedYear]);

    useEffect(() => {
        if (successDonarDetails) {
            setDonarDetails(successDonarDetails);
        }
    }, [successDonarDetails]);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchedColumn(dataIndex);
        const filtered = donarDetails?.donationDetailsList?.filter(item =>
            item[dataIndex]?.toString()?.toLowerCase()?.includes(selectedKeys[0].toLowerCase())
        );
        setFilteredData(filtered);
    };

    useEffect(() => {
        setFilteredData(donarDetails?.donationDetailsList);
    }, [donarDetails?.donationDetailsList]);

    const handleReset = (clearFilters, dataIndex, setSelectedKeys, selectedKeys, confirm) => {
        clearFilters();
        setSearchedColumn('');
        setSelectedKeys([]);
        setFilteredData(donarDetails?.donationDetailsList);
        handleSearch(selectedKeys, confirm, dataIndex);
        mutateGetWachDonationDetails({ month: selectedMonth, year: selectedYear });
    };

    const IconComponent = () => {
        const handleIconClick = () => {
            console.log('Icon clicked!');
        };

        return (
            <ProfileOutlined size='large' onClick={handleIconClick} />
        );
    };

    const renderCell = (text, type) => {
        switch (type) {
            case 'Icon':
                return <IconComponent />;
            default:
                return text;
        }
    };

    const getColumnSearchProps = (dataIndex, type) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                {selectedKeys.length > 0 && (
                    <>
                        <Button
                            type="link"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                            disabled={selectedKeys.length === 0}
                        >
                            Search
                        </Button>
                        <Button
                            type="link"
                            size="small"
                            onClick={() => handleReset(clearFilters, dataIndex, setSelectedKeys, selectedKeys, confirm)}
                        >
                            Reset
                        </Button>
                    </>
                )}
            </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => document.querySelector('.ant-table-filter-dropdown input')?.select(), 100);
            }
        },
        render: (text) => {
            const renderedText = renderCell(text, type);
            return searchedColumn === dataIndex ? (
                <span>{renderedText}</span>
            ) : (
                renderedText
            );
        },
    });

    const columns = DonationTableConfig.Columns.map(column => ({
        ...column,
        ...getColumnSearchProps(column.dataIndex, column.type, column.align),
        align: column?.align
    }));

    const handleMonthChange = (value) => {
        setSelectedMonth(value); // Store the month as a number
    };

    const handleYearChange = (value) => {
        setSelectedYear(value);
    };
   const handleResetDate = () => {
    setSelectedMonth(currentMonth);
    setSelectedYear(currentYear);
    mutateGetWachDonationDetails({ month: currentMonth, year: currentYear });
  };
    // Generate year options from current year to past 5 years
    const yearOptions = Array.from({ length: 6 }, (_, i) => currentYear - i);

    const monthOptions = [
        { name: 'January', value: 1 },
        { name: 'February', value: 2 },
        { name: 'March', value: 3 },
        { name: 'April', value: 4 },
        { name: 'May', value: 5 },
        { name: 'June', value: 6 },
        { name: 'July', value: 7 },
        { name: 'August', value: 8 },
        { name: 'September', value: 9 },
        { name: 'October', value: 10 },
        { name: 'November', value: 11 },
        { name: 'December', value: 12 }
    ];
    
    return (
        <div>
            <Row justify="end" style={{ marginBottom: '20px', marginTop:'20px',marginRight:'10px' }}>
                <Col>
                    <Select
                        value={selectedMonth}
                        onChange={handleMonthChange}
                        style={{ width: 120, marginRight: 10 }}
                        placeholder="Select Month"
                    >
                        {monthOptions.map((month) => (
                            <Select.Option key={month.value} value={month.value}>
                                {month.name}
                            </Select.Option>
                        ))}
                    </Select>
                    <Select
                        value={selectedYear}
                        onChange={handleYearChange}
                        style={{ width: 120 }}
                        placeholder="Select Year"
                    >
                        {yearOptions.map(year => (
                            <Select.Option key={year} value={year}>{year}</Select.Option>
                        ))}
                    </Select>
                    <ReloadOutlined
            onClick={handleResetDate}
            style={{ fontSize: 20, cursor: 'pointer', marginLeft: 10 }}
        />
                </Col>
            </Row>

            <Table
                columns={columns}
                dataSource={filteredData}
                rowKey={(record, index) => index}
                loading={isLoading}
                style={{ width: '100%' }}
                pagination={{
                    total: donarDetails?.donationDetailsList?.length,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    showTotal: (total) => `Total ${total} items`,
                }}
            />
        </div>
    );
};

export default DonarDetails;
