import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Header from "./Mainpage/Header";
import Footer from "./Mainpage/Footer";
import "./Mainpage/css/mainpage.css";
import Home from "./Mainpage/Home";

const MainPage = () => {
  const [selectedComponent, setSelectedComponent] = useState(null);

  return (
    <div className="page">
      <div className="background bgWrapper">
        <Helmet>
          <title>We All Can Help</title>
        </Helmet>
        <header className="headerWrapper">
          <Header selectedComponent={selectedComponent} setSelectedComponent={setSelectedComponent} />
        </header>

        <div className="movie-list">
          {/* Render the selected component here */}
          {selectedComponent ? selectedComponent : <Home/>}
        </div>

        <div className="footer-wrapper">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default MainPage;
