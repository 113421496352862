import React, { useState, useEffect } from 'react';
import { Table, Button, Select, message } from 'antd';
import dayjs from 'dayjs';
import { ReloadOutlined } from '@ant-design/icons';
import useWachDonationsMade from '../../Hooks/getdonationsMade';
import { donationsMade } from '../../../Configs/Config';
import DonationFormModal from './DonationsFormModal';
import useAddWachDonationsMade from '../../Hooks/AddDonationsMade';

const { Option } = Select;

const DonationsMadeTable = ({ loggedinUserDetails }) => {
  const [dataSource, setDataSource] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedYear, setSelectedYear] = useState(dayjs().year());
  const [selectedRow, setSelectedRow] = useState(null);
  const [operationType, setOperationType] = useState(null);
  const [listofDonationIds,setListofDonationIds]=useState([]);
  const { mutate: mutateGetWachDonationsMade, isLoading, data: successDonationsMade } = useWachDonationsMade();
  const { mutate: mutateAddDonationWeMade, data: successAddDonationsMade, error: isAddDonationsMadeError, isLoading: isAddDonationsMadeLoading } = useAddWachDonationsMade();
    // Generate the donationId value: LD + currentYear + currentMonth
    const currentYear = dayjs().year();  // Hardcoded year
    const currentMonth = dayjs().month() + 1;  // Current month (0-based index, hence +1)
    const formattedMonth = currentMonth < 10 ? `0${currentMonth}` : currentMonth; // Ensure two digits for the month
    const donationIdValue = `LD${currentYear}${formattedMonth}`;
  
  useEffect(() => {
    mutateGetWachDonationsMade({ year: selectedYear });
  }, [selectedYear]);

  useEffect(() => {
    if (successDonationsMade) {
      const updatedDataSource = successDonationsMade?.donationsMadeList?.map((item) => ({
        key: item?.id,
        ...item,
      }));
      if(selectedYear === dayjs().year()){
        setListofDonationIds(successDonationsMade?.donationsMadeList?.map((item) => item?.donationId));
      }
      setDataSource(updatedDataSource);
    }
  }, [successDonationsMade]);
  useEffect(() => {
    // Check for successful donation addition
    if (successAddDonationsMade && successAddDonationsMade.statusCode !== "-1") {
      const year = dayjs().year();
      setSelectedYear(year);
      message.success(successAddDonationsMade?.statusMessage);
      mutateGetWachDonationsMade({ year });
      setIsModalVisible(false);
      setSelectedRow(null);
    } else if (successAddDonationsMade?.statusCode === "-1") {
      // Handle the failure scenario
      setIsModalVisible(false);
      setSelectedRow(null);
      message.error(successAddDonationsMade?.statusMessage);
    }
  }, [successAddDonationsMade]);

  useEffect(() => {
    // Check for error conditions (network issues or other failures)
    if (isAddDonationsMadeError) {
      setIsModalVisible(false);
      setSelectedRow(null);
      message.error('Failed to Add Donation');
    }
  }, [isAddDonationsMadeError]);
  // Prepare initial values for the form
  const getInitialValues = () => {
    if (selectedRow) {
      return donationsMade.reduce((acc, config) => {
        const value = selectedRow[config.dataIndex];
        if (config.inputType === 'date' && value) {
          acc[config.dataIndex] = dayjs(value);
        } else {
          acc[config.dataIndex] = value;
        }
        return acc;
      }, {});
    }
    return {
      donationId: donationIdValue,  // Set default value for donationId
    };
  };
  const AddIntialValues = {
    donationId: donationIdValue,
  };
  const handleAdd = () => {
    setIsModalVisible(true);
    setSelectedRow(null);
    setOperationType('Add');
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedRow(null);
    setOperationType(null);
  };
  const handleEdit = (record) => {
    setIsModalVisible(true);
    setSelectedRow(record);
    setOperationType('Edit');
  };

  const handleFormSubmit = (input) => {
    input['donationInfoAddedBy'] = loggedinUserDetails?.adminInformation?.username;
    input['donationInfoAddedByEmail'] = loggedinUserDetails?.adminInformation?.emailId;
    input['functionName'] = operationType === 'Edit' ? 'updateDonationMadeDetails' : 'addNewDonationMadeDetails';
    if (operationType === 'Edit') {
      input['id'] = selectedRow?.id;
    }
     mutateAddDonationWeMade({ input });
  };

  const handleYearChange = (value) => {
    setSelectedYear(value);
  };

  const handleReset = () => {
    setSelectedYear(dayjs().year());
  };

  const columns = donationsMade.map((col) => {
    const column = {
      ...col,
      render: (text, record) => {
        if (col.dataIndex === 'donationId') {
          return (
            <span
              style={{ cursor: 'pointer', color: '#1890ff' }}
              onClick={() => handleEdit(record)}
            >
              {text}
            </span>
          );
        }
        return text;
      },
      onCell: (record) => {
        // Apply nowrap style conditionally
        if (col.dataIndex === 'donationDate' || col.dataIndex === 'charityName') {
          return {
            style: {
              whiteSpace: 'nowrap',
            },
          };
        }
        return { };
      },
    };
  
    return column;
  });
  

  const yearOptions = Array.from({ length: 5 }, (_, i) => currentYear - i);

  return (
    <div className="container">
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
        <div>
          <Button onClick={handleAdd} type="primary">
            Add New Donation
          </Button>
        </div>
        <div>
          <Select
            value={selectedYear}
            style={{ width: 120, marginRight: 8 }}
            onChange={handleYearChange}
          >
            {yearOptions.map((year) => (
              <Option key={year} value={year}>
                {year}
              </Option>
            ))}
          </Select>
          <ReloadOutlined onClick={handleReset} />
        </div>
      </div>
      <div style={{maxWidth :"150vh"}}>
      <Table
        bordered
        dataSource={dataSource}
        columns={columns}
        loading={isLoading}
        pagination= {false}
        style={{
          maxWidth: '100%',
          overflowX: 'auto',
          maxHeight: '60vh',
        }}
      />
      </div>
      <DonationFormModal
        visible={isModalVisible}
        onClose={handleModalClose}
        onSubmit={handleFormSubmit}
        configs={donationsMade}
        isAddDonationsMadeLoading={isAddDonationsMadeLoading}
        selectedRow={selectedRow}
        listofDonationIds={listofDonationIds}
        getInitialValues={getInitialValues}
        ActiveAdmins = {loggedinUserDetails?.adminUserIds}
        operationType = {operationType}
        AddIntialValues={AddIntialValues}
        donationIdValue={donationIdValue}
      />
    </div>
  );
};

export default DonationsMadeTable;
