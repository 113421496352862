import React, { useEffect, useState } from 'react';
import useWachDonationDetails from '../../Hooks/getDonarDetails';

const AnnouncementBar = () => {
    // const [isVisible, setIsVisible] = useState(true);
    // const [message, setMessage] = useState();
    const { mutate: mutateGetWachDonationDetails, isLoading, data: successDonarDetails } = useWachDonationDetails();

    useEffect(() => {
        mutateGetWachDonationDetails({ month: undefined, year: undefined });
    }, [])

    // useEffect(() => {
    //     if (successDonarDetails) {
    //         setMessage(successDonarDetails?.bannerMessage);
    //     }

    // }, [successDonarDetails])


    // const handleClose = () => {
    //     setIsVisible(false);
    // };

    // if (!isVisible) {
    //     return null;
    // }
    return successDonarDetails?.bannerMessage
    // return (
    //     successDonarDetails?.bannerMessage && (
    //         <div style={{
    //             backgroundColor: '#ff0000',
    //             color: '#ffffff',
    //             textAlign: 'center',
    //             padding: '20px',
    //             fontWeight: 'bold',
    //             width: '100%',
    //             zIndex: 1000,
    //             position: 'relative',
    //             display: 'flex',
    //             justifyContent: 'center',
    //             alignItems: 'center',
    //         }}>
    //             <span>{message}</span>
    //             <button
    //                 onClick={handleClose}
    //                 style={{
    //                     position: 'absolute',
    //                     right: '10px',
    //                     background: 'none',
    //                     border: 'none',
    //                     color: 'white',
    //                     fontSize: '20px',
    //                     cursor: 'pointer',
    //                 }}
    //             >
    //                 &times;
    //             </button>
    //         </div>
    //     )
    // );
};

export default AnnouncementBar;
