import { UserOutlined, MailOutlined, MobileOutlined, CommentOutlined } from '@ant-design/icons';
const RupeeSymbol = () => <span style={{ fontSize: '16px', fontWeight: 500 }}>₹</span>;

export const NavArray = ["Home", "DonateNow", "DonarDetails", "Contact"]

export const DonationFormConfig = {
  Fields: [
    {
      Label: 'First Name',
      Type: 'Text',
      PlaceHolder: 'First Name',
      Field: 'firstName',
      Required: true,
      Icon: UserOutlined
    },
    {
      Label: 'Last Name',
      Type: 'Text',
      PlaceHolder: 'last Name',
      Field: 'lastName',
      Required: true,
      Icon: UserOutlined
    },
    {
      Label: 'Amount',
      Type: 'Number',
      PlaceHolder: 'Amount',
      Field: 'amount',
      Required: true,
      Icon: RupeeSymbol
    },
    {
      Label: 'Mobile Number',
      Type: 'Phone',
      PlaceHolder: 'Mobile Number',
      Field: 'mobileNumber',
      Required: true,
      Icon: MobileOutlined
    },
    {
      Label: 'Email ID',
      Type: 'Email',
      PlaceHolder: 'Email ID',
      Field: 'emailId',
      Required: true,
      Icon: MailOutlined
    },
    {
      Label: 'Comments',
      Type: 'TextArea',
      PlaceHolder: 'Comments',
      Field: 'comments',
      Required: false,
      Icon: CommentOutlined
    },
  ]
}

export const DonationTableConfig = {
  Columns: [
    {
      title: 'Id',
      dataIndex: 'userId',
      key: 'id',
      type: 'Text',
      align: 'center'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      type: 'Text',
      align: 'center'
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      type: 'Text',
      align: 'center'
    },
    {
      title: 'Donation Date',
      dataIndex: 'donationDate',
      key: 'donationDate',
      type: 'Text',
      align: 'center'
    },
    // {
    //   title:' MoreDetails',
    //   dataIndex: 'moreDetails',
    //   key: 'moreDetails',
    //   type:'Icon',
    //   align:'center'
    // }      
  ]
}
export const HomeSliderImages = ["https://wach-gallery.s3.ap-south-1.amazonaws.com/slide-1.jpg",
  "https://wach-gallery.s3.ap-south-1.amazonaws.com/slide-2.jpg",
  "https://wach-gallery.s3.ap-south-1.amazonaws.com/slide-3.jpg",
  "https://wach-gallery.s3.ap-south-1.amazonaws.com/slide-4.jpg",
  "https://wach-gallery.s3.ap-south-1.amazonaws.com/slide-5.jpg",
  "https://wach-gallery.s3.ap-south-1.amazonaws.com/slide-6.jpg",
  "https://wach-gallery.s3.ap-south-1.amazonaws.com/slide-7.jpg",
  "https://wach-gallery.s3.ap-south-1.amazonaws.com/slide-8.jpg"];

export const DashboardDetails = [
  {
    title: 'Life Time Donations',
    field : 'donationsReceivedLifeTime',
    color: '#FF5733',
    sortorder : 1,
    isview : false
  },
  {
    title: 'This Month Donations',
    field : 'donationsReceivedThisMonth',
    color: '#33FF57',
    sortorder : 2,
    isview : false
  },
  {
    title: 'This Year Donations',
    field : 'donationsReceivedThisYear',
    color: '#3357FF',
    sortorder : 3,
    isview : false
  },
  {
    title: 'Pending Payment Requests',
    field : 'pendingPaymentRequests',
    color: '#FF33F7',
    sortorder : 4,
    isview : true
  },
  // {
  //   title: 'Pending Certificate Requests',
  //   field : 'pendingCertSendingRequests',
  //   color: '#F7FF33',
  //   sortorder : 5,
  //   isview : true
  // },
  {
    title: 'Total Donors (Life Time)',
    field : 'totalNumOfDonorsTillNow',
    color: '#33FFF7',
    sortorder : 5,
    isview : false
  },
  {
    title: 'Total Donors (This Month)',
    field : 'totalNumOfDonorsThisMonth',
    color: '#FF33F7',
    sortorder : 6,
    isview : false
  },
  {
    title: 'Donations We Made',
    field : 'donationsWeMade',
    color: '#F7FF33',
    sortorder : 7,
    isview : true
  }
]

export const donationsMade=  [
  {
    title :'Donation Id',
    dataIndex: 'donationId',
    editable: true,
    inputType : 'text',
    required : true,
    disabled : true,
    adminOnly : false
  },
  {
    title: 'Donation Date',
    dataIndex: 'donationDate',
    editable: true,
    inputType : 'date',
    required : true,
    disabled : false,
    adminOnly : false
  },
  {
    title: 'Charity Name',
    dataIndex: 'charityName',
    width: '30%',
    editable: true,
    inputType : 'text',
    required : true,
    disabled : false,
    adminOnly : false
  },
  {
    title: 'Donation Location',
    dataIndex: 'donationLocation',
    editable:true,
    inputType : 'text',
    required : true,
    disabled : false,
    adminOnly : false
  },
  {
    title: 'Donation Category',
    dataIndex: 'donationCategory',
    editable : true,
    inputType : 'text',
    required : true,
    disabled : false,
    adminOnly : true
  },
  {
    title: 'Donation Items',
    dataIndex: 'donatedItems',
    editable:true,
    inputType : 'text',
    required : true,
    disabled : false,
    adminOnly : true
  },
  {
    title: 'Donation People',
    dataIndex: 'donatedPeople',
    editable:true,
    inputType : 'text',
    required : true,
    disabled : false,
    adminOnly : true
  },
  {
   title: 'Approved By',
    dataIndex: 'approvedBy',
    editable:true,
    inputType : 'select',
    required : true,
    disabled : false,
    adminOnly : true
  },
  {
    title: 'Donation Comments',
    dataIndex: 'donationComments',
    editable:true,
    inputType : 'text',
    required : true,
    disabled : false,
    adminOnly : false
  },
  {
    title:'Collected Amount',
    dataIndex:'collectedAmount',
    editable:true,
    inputType : 'text',
    required : true,
    disabled : false,
    adminOnly : false
  },
  {
    title:'Donation Amount',
    dataIndex:'donationAmount',
    editable:true,
    inputType : 'text',
    required : true,
    disabled : false,
    adminOnly : false
  },
  {
    title:'Remaining Amount',
    dataIndex:'remainingAmount',
    editable:true,
    inputType : 'text',
    required : true,
    disabled : false,
    adminOnly : false
  }
];

export const months = [
  { name: "January", images: ["https://wach-gallery.s3.ap-south-1.amazonaws.com/slide-1.jpg",
  "https://wach-gallery.s3.ap-south-1.amazonaws.com/slide-2.jpg", "https://wach-gallery.s3.ap-south-1.amazonaws.com/slide-1.jpg",
  "https://wach-gallery.s3.ap-south-1.amazonaws.com/slide-2.jpg", "https://wach-gallery.s3.ap-south-1.amazonaws.com/slide-1.jpg",
  "https://wach-gallery.s3.ap-south-1.amazonaws.com/slide-2.jpg"], color: "#36454F" },

  { name: "February", images: ["https://wach-gallery.s3.ap-south-1.amazonaws.com/slide-1.jpg",
    "https://wach-gallery.s3.ap-south-1.amazonaws.com/slide-2.jpg"], color: "#003366" },

  { name: "March", images: ["https://wach-gallery.s3.ap-south-1.amazonaws.com/slide-1.jpg",
    "https://wach-gallery.s3.ap-south-1.amazonaws.com/slide-2.jpg"], color: "#228B22" },

  { name: "April", images: ["https://wach-gallery.s3.ap-south-1.amazonaws.com/slide-1.jpg",
    "https://wach-gallery.s3.ap-south-1.amazonaws.com/slide-2.jpg"], color: "#800020" },

  { name: "May", images: ["https://wach-gallery.s3.ap-south-1.amazonaws.com/slide-1.jpg",
    "https://wach-gallery.s3.ap-south-1.amazonaws.com/slide-2.jpg"], color: "#5D3F6A" },

  { name: "June", images: ["https://wach-gallery.s3.ap-south-1.amazonaws.com/slide-1.jpg",
    "https://wach-gallery.s3.ap-south-1.amazonaws.com/slide-2.jpg"], color: "#2F4F4F" },

  { name: "July", images: ["https://wach-gallery.s3.ap-south-1.amazonaws.com/slide-1.jpg",
    "https://wach-gallery.s3.ap-south-1.amazonaws.com/slide-2.jpg"], color: "#3E1C41" },

  { name: "August", images: ["https://wach-gallery.s3.ap-south-1.amazonaws.com/slide-1.jpg",
    "https://wach-gallery.s3.ap-south-1.amazonaws.com/slide-2.jpg"], color: "#014D47" },

  { name: "September", images: ["https://wach-gallery.s3.ap-south-1.amazonaws.com/slide-1.jpg",
    "https://wach-gallery.s3.ap-south-1.amazonaws.com/slide-2.jpg"], color: "#3E2B2B" },

  { name: "October", images: ["https://wach-gallery.s3.ap-south-1.amazonaws.com/slide-1.jpg",
    "https://wach-gallery.s3.ap-south-1.amazonaws.com/slide-2.jpg"], color: "#C04000" },

  { name: "November", images: ["https://wach-gallery.s3.ap-south-1.amazonaws.com/slide-1.jpg",
    "https://wach-gallery.s3.ap-south-1.amazonaws.com/slide-2.jpg"], color: "#000080" },

  { name: "December", images: ["https://wach-gallery.s3.ap-south-1.amazonaws.com/slide-1.jpg",
    "https://wach-gallery.s3.ap-south-1.amazonaws.com/slide-2.jpg"], color: "#353839" },
];


