import React, { useState, useEffect } from 'react';
import { Table, Select } from 'antd';
import dayjs from 'dayjs';
import { ReloadOutlined } from '@ant-design/icons';
import useWachDonationsMade from '../../Hooks/getdonationsMade';
import { donationsMade } from '../../../Configs/Config';

const { Option } = Select;

const DonationsMade = () => {
  const [dataSource, setDataSource] = useState([]);
  const [selectedYear, setSelectedYear] = useState(dayjs().year());
  const [selectedRow, setSelectedRow] = useState(null);
  const { mutate: mutateGetWachDonationsMade, isLoading, data: successDonationsMade } = useWachDonationsMade();
  const currentYear = dayjs().year();  // current Year

  useEffect(() => {
    mutateGetWachDonationsMade({ year: selectedYear });
  }, [selectedYear]);

  useEffect(() => {
    if (successDonationsMade) {
      const updatedDataSource = successDonationsMade?.donationsMadeList?.map((item) => ({
        key: item?.id,
        ...item,
      }));
      setDataSource(updatedDataSource);
    }
  }, [successDonationsMade]);


  const handleYearChange = (value) => {
    setSelectedYear(value);
  };

  const handleReset = () => {
    setSelectedYear(dayjs().year());
  };
 const fields = donationsMade?.filter((item)=>!item?.adminOnly)
  const columns = fields?.map((col) => {
    const column = {
      ...col,
      render: (text, record) => {
        if (col.dataIndex === 'donationId') {
          return (
            <span
              style={{ cursor: 'pointer', color: '#1890ff' }}
              onClick={() => setSelectedRow(record)}
            >
              {text}
            </span>
          );
        }
        return text;
      },
      onCell: (record) => {
        // Apply nowrap style conditionally
        if (col.dataIndex === 'donationDate' || col.dataIndex === 'charityName') {
          return {
            style: {
              whiteSpace: 'nowrap',
            },
          };
        }
        return {};
      },
    };

    return column;
  });


  const yearOptions = Array.from({ length: 5 }, (_, i) => currentYear - i);

  return (
    <div className="container">
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop:'10px',marginBottom:'10px' }}>
        <div>
          <Select
            value={selectedYear}
            style={{ width: 120, marginRight: 8 }}
            onChange={handleYearChange}
          >
            {yearOptions.map((year) => (
              <Option key={year} value={year}>
                {year}
              </Option>
            ))}
          </Select>
          <ReloadOutlined onClick={handleReset} />
        </div>
      </div>
      <div >
        <Table
          bordered
          dataSource={dataSource}
          columns={columns}
          loading={isLoading}
          pagination= {false}
          style={{
            maxWidth: '100%',
            overflowX: 'auto',
            maxHeight: '70vh',
          }}
        />
      </div>
    </div>
  );
};

export default DonationsMade;
