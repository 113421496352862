import { useMutation, useQueryClient } from 'react-query';

const useWachDonationDetails = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ year, month }) => {
      const payload ={
        "functionName": "getDonationDetails",
        "year": year ? year.toString() : undefined,
        "month": month? month.toString() :undefined
    };
      const response = await fetch('https://z6qeyg2lo2.execute-api.ap-south-1.amazonaws.com/dev/wachDonationDetails', {
        method: 'POST',
        headers: {
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      return response.json();
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData('donationDetails', data);
      },
      onError: (error) => {
        console.error('Error fetching donation details:', error);
      },
      retry: 3, 
    }
  );
};

export default useWachDonationDetails;
