import './AdminHome.css'
import { DashboardDetails } from "../../../Configs/Config"
import { Card } from 'antd'

const AdminDashboardHome = ({dashboardetails,setSelectedCard}) => {

    // Handler function for view button click
    const handleViewClick = (item) => {
        setSelectedCard(item);
    };

    return (
        <div className="admin-home-dashboard">
        <div className="dashboard-cards">
            {DashboardDetails && DashboardDetails.map((item, index) => {
                return (
                    <Card 
                        className="dashboard-card" 
                        style={{ backgroundColor: item?.color }} 
                        key={index}
                    >
                        <div className="dashboard-card__header">
                            <h2 className="dashboard-card__title">{item?.title}</h2>
                        </div>
                        {item?.field !== 'donationsWeMade' &&
                        <div className="dashboard-card__body">
                            <div className="dashboard-card__description">
                                {dashboardetails?.adminDashboardViewInfo?.[item?.field] || 0}
                            </div>
                        </div>
                    }
                        <div className="dashboard-card__footer">
                            {item?.isview && (
                                <button 
                                    className="dashboard-card__button"
                                    onClick={() => handleViewClick(item)}
                                >
                                   {item?.field === "donationsWeMade" ? 'ADD/Update' : 'View'}
                                </button>
                            )}
                        </div>
                    </Card>
                )
            })}
        </div>
    </div>
    )
}

export default AdminDashboardHome;
