import React, { useEffect } from 'react';
import { Modal, Form, Input, InputNumber, Select, Button, DatePicker } from 'antd';
import dayjs from 'dayjs';
import './DonationsFormModal.css';

const { Option } = Select;

const DonationFormModal = ({
  visible,
  onClose,
  onSubmit,
  configs,
  isAddDonationsMadeLoading,
  selectedRow,
  listofDonationIds,
  getInitialValues,
  ActiveAdmins,
  operationType,
  AddIntialValues,
  donationIdValue
}) => {
  const [form] = Form.useForm();
  useEffect(() => {
    if (selectedRow) {
      const initialValues = getInitialValues();
      form.setFieldsValue(initialValues); // Update form values whenever selectedRow changes
    } else {
      form.resetFields(); // Reset fields when there is no selectedRow
    }
  }, [selectedRow, form]);

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      // Format date values for submission if needed
      if (values.donationDate) {
        values.donationDate = dayjs(values.donationDate).format('YYYY-MM-DD HH:mm:ss');
      }
      onSubmit(values);
      form.resetFields();
    } catch (error) {
      console.error('Validation Failed:', error);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  const renderFormItem = (config) => {
    const { title, dataIndex, editable, inputType, disabled } = config;
    if (!editable) return null;
  
    let inputComponent;
  
    // Updated selectComponent function to handle different options based on dataIndex
    const selectComponent = (dataIndex) => {
      if (dataIndex === 'approvedBy') {
        return (
          <Select disabled={disabled}>
            {(ActiveAdmins || []).map((option) => (
              <Option key={option} value={option}>
                {option}
              </Option>
            ))}
          </Select>
        );
      } else {
        return (
          <Select disabled={disabled}>
            {(config?.options || []).map((option) => (
              <Option key={option} value={option}>
                {option}
              </Option>
            ))}
          </Select>
        );
      }
    };
  
    // Handle different input types
    switch (inputType) {
      case 'number':
        inputComponent = <InputNumber style={{ width: '100%' }} disabled={disabled} />;
        break;
      case 'select':
        inputComponent = selectComponent(dataIndex); // Use updated selectComponent logic
        break;
      case 'date':
        inputComponent = <DatePicker
        style={{ width: '100%' }}
        showTime
        format="YYYY-MM-DD HH:mm:ss"
        disabled={disabled}
      />;
        break;
      default:
        inputComponent = <Input disabled={disabled} />;
    }
  
    return (
      <Form.Item
        key={dataIndex}
        name={dataIndex}
        label={title}
        rules={[{ required: true, message: `Please enter ${title.toLowerCase()}` }, 
          {
            validator(_, value) {
              if (dataIndex === 'donationId' && listofDonationIds.includes(value) && operationType ==='Add') {
                return Promise.reject(new Error(`per month max you can add only one record if required you can modify the ${donationIdValue} record`));
              }
              return Promise.resolve();
            },
          },
        ]}>
        {inputComponent}
      </Form.Item>
    );
  };
  

  return (
    <Modal
      title={selectedRow ? 'Edit Donation' : 'Add New Donation'}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      loading={isAddDonationsMadeLoading}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk} loading={isAddDonationsMadeLoading}>
          Submit
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        className="donation-form"
        initialValues={operationType === "Add" ? getInitialValues() : AddIntialValues} // Set initialValues for the form
      >
        {configs.map(renderFormItem)}
      </Form>
    </Modal>
  );
};

export default DonationFormModal;
