import React, { useState } from 'react';
import { Form, Input, Button, Spin, message } from 'antd';
import './AdminLogin.css';
import Sample from './../../../img/Sample.jpg'
import AdminHome from './AdminHome';
const LoginPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isloginSucess,setIsLoginSuccess]= useState(false);
    const [loggedinUserDetails,setLoggedinUserDetails]= useState({});

    const handleSubmit = async (values) => {
        setIsLoading(true);
        
        const Input = {
          username: values?.username,
          password: values?.password,
          loginCode: values?.loginCode,
          functionName: "validateAdminLogin"
        };
      
        try {
          const response = await fetch('https://z6qeyg2lo2.execute-api.ap-south-1.amazonaws.com/dev/wachDonationDetails', {
            method: 'POST',
            body: JSON.stringify(Input),
            headers: {
              'Content-Type': 'application/json',
            },
          });
      
          const responseData = await response.json(); // Parse the JSON response
          // You can add more logic here, e.g., check the response status or update state
      
          if (responseData.statusCode === "1") {
            setIsLoginSuccess(true);
            setLoggedinUserDetails(responseData);
            // Handle success (e.g., redirect to another page, update state)
          } else {
            message.error(responseData?.statusMessage);
            // Handle failure (e.g., show error message)
          }
        } catch (error) {
          console.error("An error occurred:", error);
        } finally {
          setIsLoading(false); 
        }
      };
      

    return (
        <>
        {!isloginSucess &&
        <div className="login-container">
            <div className='login-content'>
                <div className="login-image">
                    <img src={Sample} alt="Login" />
                </div>
                <div className="login-box">
                    <h2>Login</h2>
                    <Spin spinning={isLoading} tip="Loading...">
                        <Form onFinish={handleSubmit} layout="vertical">
                            <Form.Item
                                label="Username"
                                name="username"
                                rules={[{ required: true, message: 'Please enter your username!' }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[{ required: true, message: 'Please enter your password!' }]}
                            >
                                <Input.Password />
                            </Form.Item>
                            <Form.Item
                                label="Login Code"
                                name="loginCode"
                                rules={[{ required: true, message: 'Please enter your loginCode!' },
                                {
                                    pattern: /^[a-zA-Z0-9]+$/,
                                    message: 'Login code can only contain letters and numbers'
                                },
                                { max: 5, message: 'Login code cannot be longer than 3 characters' },
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit" block>
                                    Login
                                </Button>
                            </Form.Item>
                        </Form>
                    </Spin>
                </div>
            </div>
        </div>
        }
        {isloginSucess && <AdminHome loggedinUserDetails = {loggedinUserDetails} isloginSucess ={isloginSucess}/>}
        </>
    );
};

export default LoginPage;
