import { message } from "antd"
import { useEffect, useState } from "react"
import './AdminHome.css'
import AdminSidebar from "./AdminSidebar"
import AdminDashboardHome from "./AdminDashboardHome"
import AdminPaymentAprove from "./AdminPaymentAprove"
import DonationsMadeTable from "./DonationsMadeTable"

const AdminHome = ({ loggedinUserDetails, isloginSucess }) => {
    const [dashboardetails, setDashbordDetails] = useState({})
    const [selectedCard, setSelectedCard] = useState(null);
    const [pendingPaymentRequestsData, setPendingPaymentRequestData] = useState(null);

    const getDashboardDetailsForAdmin = () => {
        let Input = {
            functionName: "getDashboardDetailsForAdmin"
        }
        if (isloginSucess) {
            fetch('https://z6qeyg2lo2.execute-api.ap-south-1.amazonaws.com/dev/wachDonationDetails', {
                method: 'POST',
                body: JSON.stringify(Input),
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then((res) => res.json()).then((data) => {
                setDashbordDetails(data);
            }).catch((err) => {
                message.error("Failed to load Dashboard details")
            })
        }
    };  
    
    useEffect(() => {
        getDashboardDetailsForAdmin();
    }, [isloginSucess]);
    const getPendingPaymentRequests = () => {
        if (selectedCard?.field === "pendingPaymentRequests") {
            fetch('https://z6qeyg2lo2.execute-api.ap-south-1.amazonaws.com/dev/wachDonationDetails', {
                method: 'POST',
                body: JSON.stringify({
                    functionName: "getPendingPaymentRequests"
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then((res) => res.json()).then((data) => {
                setPendingPaymentRequestData(data);
            }).catch((err) => {
                message.error("Failed to load Dashboard details")
            })
        }
    };
    useEffect(() => {
        getPendingPaymentRequests();
    }, [selectedCard])
    return (
        <>
            <div className="admin-home-container">
                {/* Admin Dashboard Title - Entire Body */}
                <div className="admin-dashboard-title">
                    <h2>
                        Admin Dashboard for <span style={{ color: "red" }}>
                            {loggedinUserDetails?.adminInformation?.name}
                        </span>
                    </h2>
                </div>

                <div className="admin-home-content">
                    <AdminSidebar loggedinUserDetails={loggedinUserDetails} setSelectedCard={setSelectedCard} getDashboardDetailsForAdmin={getDashboardDetailsForAdmin} />
                    {!selectedCard && (
                        <AdminDashboardHome dashboardetails={dashboardetails} setSelectedCard={setSelectedCard} />
                    )}
                    {selectedCard?.field === "pendingPaymentRequests" &&
                        <AdminPaymentAprove pendingPaymentRequestsData={pendingPaymentRequestsData} loggedinUserDetails={loggedinUserDetails} getPendingPaymentRequests={getPendingPaymentRequests} />
                    }
                    {selectedCard?.field === "donationsWeMade" &&
                        <DonationsMadeTable loggedinUserDetails={loggedinUserDetails} />
                    }
                </div>
            </div>
        </>
    )
}

export default AdminHome;
