import React, { useState } from "react";
import "./css/mainpage.css";
import watchLogo from "./../../img/LogoIcon.png";
import wachFullLogo from "./../../img/cover-red.png";
import AnnouncementBar from "./Utils/AnnouncementBar";
import Home from "./Home";
import DonarDetails from "../DonationDetails";
import DonationsMade from "./DonationsMade/DonationsMade";
import DonationForm from "./DonationForm";
import LoginPage from "./Admin/AdminLogin";
import GalleryPage from "./Gallery";

const Header = ({ setSelectedComponent }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);

  // Function to handle click and set the corresponding component
  const handleMenuClick = (componentName) => {
    switch (componentName) {
      case "Home":
        setSelectedComponent(<Home />);
        break;
      case "DonarDetails":
        setSelectedComponent(<DonarDetails />);
        break;
      case "DonationsMade":
        setSelectedComponent(<DonationsMade />);
        break;
      case "Gallery":
         setSelectedComponent(<GalleryPage />);
        break;
      case "ContactUs":
        // setSelectedComponent(<ContactUs />);
        break;
      case "AdminLogin":
        setSelectedComponent(<LoginPage />);
        break;
      case "DonateNow":
        setSelectedComponent(<DonationForm />);
        break;
      default:
        setSelectedComponent(null);
    }

    // Close the sidebar and set the active menu item
    setSidebarOpen(false);
    setActiveMenu(componentName);
  };

  return (
    <>
      <div className="header-bar">
        <div className="header-outer-wrapper">
          <div className="header-inner-wrapper">
            <div id="menuToggle">
              <input
                className="sidebar"
                type="checkbox"
                checked={isSidebarOpen}
                onChange={() => setSidebarOpen(!isSidebarOpen)}
              />
              <span></span>
              <span></span>
              <span></span>
              <ul id="menu" className={isSidebarOpen ? "open" : ""}>
                <li>
                  <img
                    srcSet={wachFullLogo}
                    alt="WACH Logo"
                    style={{
                      width: "70%",
                      marginTop: "10px",
                    }}
                  />
                </li>
                <li
                  onClick={() => handleMenuClick("Home")}
                  className={activeMenu === "Home" ? "active" : ""}
                >
                  Home
                </li>
                <li
                  onClick={() => handleMenuClick("DonarDetails")}
                  className={activeMenu === "DonarDetails" ? "active" : ""}
                >
                  Donations Received
                </li>
                <li
                  onClick={() => handleMenuClick("DonationsMade")}
                  className={activeMenu === "DonationsMade" ? "active" : ""}
                >
                  Donations Made
                </li>
                <li
                  onClick={() => handleMenuClick("Gallery")}
                  className={activeMenu === "Gallery" ? "active" : ""}
                >
                  Gallery
                </li>
                <li
                  onClick={() => handleMenuClick("ContactUs")}
                  className={activeMenu === "ContactUs" ? "active" : ""}
                >
                  Contact Us
                </li>
                <hr className="sideNav" />
                <li
                  onClick={() => handleMenuClick("AdminLogin")}
                  className={activeMenu === "AdminLogin" ? "active" : ""}
                >
                  Admin Login
                </li>
                <li
                  onClick={() => handleMenuClick("DonateNow")}
                  className={activeMenu === "DonateNow" ? "active" : ""}
                >
                  Donate Now
                </li>
              </ul>
            </div>
            <div className="logo-wrapper">
              <marquee style={{ color: "white", whiteSpace: "nowrap", width: "100%" }}>
                <AnnouncementBar />
              </marquee>
            </div>
          </div>
          <div className="navbar-wrapper">
            <nav className="nav-bar">
              <ul className="parent dropwdownmenu">
                <li>
                  <img
                    srcSet={watchLogo}
                    alt="WACH Logo"
                    style={{
                      width: "40px",
                      marginLeft: "20px",
                      marginTop: "10px",
                    }}
                  />
                </li>
                <li>
                  <button
                    className="dropbtn"
                    onClick={() => handleMenuClick("Home")}
                    style={{
                      width: "140px",
                    }}
                  >
                    Home
                  </button>
                </li>
                <li>
                  <button
                    className="dropbtn"
                    onClick={() => handleMenuClick("DonarDetails")}
                    style={{
                      width: "200px",
                    }}
                  >
                    Donations Received
                  </button>
                </li>
                <li>
                  <button
                    className="dropbtn"
                    onClick={() => handleMenuClick("DonationsMade")}
                    style={{
                      width: "160px",
                    }}
                  >
                    Donations Made
                  </button>
                </li>
                <li>
                  <button
                    className="dropbtn"
                    onClick={() => handleMenuClick("Gallery")}
                    style={{
                      width: "140px",
                    }}
                  >
                    Gallery
                  </button>
                </li>
                <li>
                  <button
                    className="dropbtn"
                    onClick={() => handleMenuClick("ContactUs")}
                    style={{
                      width: "140px",
                    }}
                  >
                    Contact Us
                  </button>
                </li>
                <li>
                  <button
                    className="dropbtn"
                    onClick={() => handleMenuClick("AdminLogin")}
                    style={{
                      width: "160px",
                    }}
                  >
                    Admin Login
                  </button>
                </li>
                <li className="dropdown-item redeem">
                  <button
                    className="dropbtn"
                    onClick={() => handleMenuClick("DonateNow")}
                  >
                    Donate Now
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
